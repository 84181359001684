import "../scss/app.scss";

import.meta.glob(["../fonts/**", "../images/**"]);

import Splide from "@splidejs/splide";
import moment from "moment";

import "./bootstrap";
import Intl from "./intl";
import Tab from "./tabs";
import FormSubmitHandler from "./form";
import DataFetcher from "./fetcher";
import EmailSend from "./email";
import debounce from "./debounce";

const humberger = document.querySelector(".menu-humberger");
const csrfToken = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");

if (humberger) {
    const mobileMenu = document.querySelector(".menu-mobile-content");
    humberger.addEventListener("click", () => {
        if (mobileMenu.classList.contains("active")) {
            mobileMenu.classList.remove("active");
        } else {
            mobileMenu.classList.add("active");
        }
    });
}

const menuToggle = document.querySelector(".menu-toggle");
if (menuToggle) {
    const asideMenu = document.querySelector(".office-menu");
    menuToggle.addEventListener("click", (e) => {
        e.preventDefault();

        if (asideMenu.classList.contains("toggle-menu")) {
            asideMenu.classList.remove("toggle-menu");
        } else {
            asideMenu.classList.add("toggle-menu");
        }
    });
}

const listItems = document.querySelectorAll(".list-item");
let activeItem = null;
listItems.forEach((element) => {
    element.addEventListener("click", (e) => {
        // e.preventDefault();
        const item = e.currentTarget;

        const menuLink = item.querySelector("a");
        if (menuLink.classList.contains("active")) {
            return;
        } else {
            if (activeItem) {
                activeItem.classList.remove("active");
            }

            activeItem = menuLink;
            activeItem.classList.add("active");
        }
    });
});

const collapseMenus = document.querySelectorAll(".collapsible-item");
let collapsedMenu = null;
collapseMenus.forEach((element) => {
    element.addEventListener("click", (e) => {
        // e.preventDefault();
        const targetItem = e.currentTarget;
        const target = e.target;

        if (targetItem.classList.contains("collapsed")) {
            if (!target.classList.contains("item")) {
                collapsedMenu = null;
                targetItem.classList.remove("collapsed");
            }
            return;
        } else {
            if (collapsedMenu) {
                collapsedMenu.classList.remove("collapsed");
            }

            collapsedMenu = targetItem;
            collapsedMenu.classList.add("collapsed");
        }
    });
});

const optionToggle = document.querySelector(".option-toggle-button");
if (optionToggle) {
    optionToggle.addEventListener("click", (e) => {
        e.preventDefault();

        const options = document.querySelector(".search-option");
        const isActive = optionToggle.classList.contains("active");
        if (isActive) {
            optionToggle.classList.remove("active");
            options.classList.remove("active");
        } else {
            optionToggle.classList.add("active");
            options.classList.add("active");
        }
    });
}

if (document.getElementById("main-carousel")) {
    document.addEventListener("DOMContentLoaded", function () {
        var mainSlider = new Splide("#main-carousel", {
            type: "fade",
            rewind: true,
            pagination: false,
            arrows: false,
        });
        mainSlider.mount();

        var thumbnailSlider = new Splide("#thumbnail-carousel", {
            type: "slide",
            fixedWidth: 170,
            // fixedHeight: 60,
            gap: 5,
            rewind: true,
            pagination: true,
            isNavigation: true,
            breakpoints: {
                600: {
                    fixedWidth: 60,
                    fixedHeight: 44,
                },
            },
            autoplay: true,
            interval: 10000,
        });

        thumbnailSlider.mount();

        mainSlider.sync(thumbnailSlider);
    });
}

document.addEventListener("DOMContentLoaded", () => {
    const openBtns = document.getElementsByClassName("popInquirypage");
    const popup = document.getElementById("popup");
    const closeBtn = document.getElementById("closeBtn");
    const overlay = document.getElementById("overlay");

    if (openBtns.length > 0 && popup && closeBtn) {
        for (const openBtn of openBtns) {
            openBtn.addEventListener(
                "click",
                debounce((event) => {
                    const vehicleId =
                        event.target.getAttribute("data-vehicle-id");
                    if (vehicleId) {
                        const quoteForm = document.querySelector(".quote-form");

                        const actionUrl = `/vehicle/quotation/request/${vehicleId}`;
                        quoteForm.setAttribute("action", actionUrl);
                    }

                    // Show the popup form
                    overlay.style.display = "block";
                    popup.style.display = "block";
                }, 5000)
            );
        }

        closeBtn.addEventListener("click", () => {
            overlay.style.display = "none";
            popup.style.display = "none";
        });

        overlay.addEventListener("click", () => {
            overlay.style.display = "none";
            popup.style.display = "none";
        });
    }
});

const items = document.querySelectorAll(".accordion button");

function toggleAccordion() {
    const itemToggle = this.getAttribute("aria-expanded");

    items.forEach((item) => {
        item.setAttribute("aria-expanded", "false");
    });

    if (itemToggle === "false") {
        this.setAttribute("aria-expanded", "true");
    }
}

items.forEach((item) => item.addEventListener("click", toggleAccordion));

// announcement carousel
if (document.getElementById("announcement-carousel")) {
    document.addEventListener("DOMContentLoaded", function () {
        new Splide("#announcement-carousel", {
            type: "loop",
            perPage: 1,
            autoplay: true,
            interval: 12000,
            speed: 2000,
            pauseOnHover: true,
            arrows: true,
            pagination: false,
        }).mount();
    });
}

// banner carousel
if (document.getElementById("banner-carousel")) {
    document.addEventListener("DOMContentLoaded", function () {
        var bannerSlider = new Splide("#banner-carousel", {
            type: "loop",
            animation: "fade",
            rewind: true,
            pagination: false,
            arrows: false,
            autoplay: true,
            speed: 3000,

            interval: 8000,
        });
        bannerSlider.mount();
    });
}

// vessels carousel
if (document.getElementById("item-carousel")) {
    document.addEventListener("DOMContentLoaded", function () {
        var vesselSlider = new Splide("#item-carousel", {
            type: "loop",
            animation: "fade",
            autoplay: true,
            speed: 1000,
            fixedWidth: 280,
            gap: 2,
            rewind: true,
            pagination: false,
            cover: true,
            isNavigation: true,
            interval: 12000,
        });
        vesselSlider.mount();
    });
}

if (document.getElementById("item-charter-carousel")) {
    document.addEventListener("DOMContentLoaded", function () {
        var vesselcharterSlider = new Splide("#item-charter-carousel", {
            type: "loop",
            animation: "fade",
            autoplay: true,
            speed: 1000,
            gap: 0,
            perPage: 4,
            rewind: true,
            pauseOnHover: true,
            cover: true,
            isNavigation: true,
            interval: 7000,
            pagination: false,
            cover: true,

            breakpoints: {
                1200: {
                    perPage: 3, // Show 3 slides per page for screens smaller than 1200px
                    gap: 15,
                },
                992: {
                    perPage: 2, // Show 2 slides per page for screens smaller than 992px
                    gap: 10,
                },
                768: {
                    perPage: 1, // Show 1 slide per page for screens smaller than 768px
                    gap: 5,
                },
            },
        });
        vesselcharterSlider.mount();
    });
}

// tabs
new Tab(".tab-form");

// upload
if (document.querySelector("[name='banners']")) {
    new FormSubmitHandler({
        picture_load_selector: "[data-type='load-pictures']",
        picture_reset_selector: "[data-type='reset-picture']",
        form_selector: "[name='banners']",
        picture_grid_selector: ".picture-grid",
        picture_input_selector: "input[name='pictures']",
        success_redirect: "/office/banners",
        validation: {
            link: "required",
            published_at: "required",
            ends_at: "required",
        },
    });
}

// upload
if (document.querySelector("[name='vehicles']")) {
    new FormSubmitHandler({
        picture_load_selector: "[data-type='load-pictures']",
        picture_reset_selector: "[data-type='reset-picture']",
        video_load_selector: "[data-type='load-video']",
        video_reset_selector: "[data-type='reset-video']",

        form_selector: "[name='vehicles']",

        picture_grid_selector: ".picture-grid",
        video_player_selector: "video",

        picture_input_selector: "input[name='pictures[]']",
        video_input_selector: "input[name='video']",

        success_redirect: "/office/vehicles",
        validation: {
            // engine_size: "required",
            // transmission: "required",
            // steering: "required",
            // model_id: "required",
            // body_type: "required",
            // fuel: "required",
            // mileage: "numeric",
            // weight: "numeric",
            // width: "numeric",
            // height: "numeric",
            // m3: "numeric",
            // seats: "required|numeric",
            // doors: "required|numeric",
            // status: "required",
            // condition: "required",
            // publish_status: "required",
            // fob_price: "numeric",
            // cnf_price: "numeric",
            // discount_percentage: "numeric",
            // featured: "boolean",
        },
    });
}

if (document.querySelector("[name='vessels']")) {
    new FormSubmitHandler({
        picture_load_selector: "[data-type='load-pictures']",
        picture_reset_selector: "[data-type='reset-picture']",
        video_load_selector: "[data-type='load-video']",
        video_reset_selector: "[data-type='reset-video']",

        form_selector: "[name='vessels']",

        picture_grid_selector: ".picture-grid",
        video_player_selector: "video",

        picture_input_selector: "input[name='pictures[]']",
        video_input_selector: "input[name='video']",

        success_redirect: "/office/vessels",
        validation: {
            // Vessel Identification
            name: "nullable|string|max:255",
            built_country: "nullable|string|max:100",
            builder: "nullable|string|max:100",
            flag: "nullable|string|max:100",

            // Vessel Specifications
            grt: "nullable|numeric|min:0",
            nrt: "nullable|numeric|min:0",
            dwt: "nullable|numeric|min:0",
            loa: "nullable|numeric|min:0",
            beam: "nullable|numeric|min:0",
            draft: "nullable|numeric|min:0",
            depth: "nullable|numeric|min:0",
            ldt: "nullable|numeric|min:0",
            lbp: "nullable|numeric|min:0",
            num_holds: "nullable|numeric|min:0",
            num_hatches: "nullable|numeric|min:0",
            deck_capacity: "nullable|numeric|min:0",
            cargo_hold_capacity: "nullable|numeric|min:0",
            hatch_size_width: "nullable|numeric",
            hatch_size_length: "nullable|numeric",
            hold_size_length: "nullable|numeric",
            hold_size_width: "nullable|numeric",
            hatch_cover: "nullable|string|max:100",
            crane: "nullable|string|max:100",
            derrick: "nullable|string|max:100",
            deck_type: "nullable|string|max:100",

            // Technical Specifications
            main_engine_type: "nullable|string|max:100",
            main_engine_power: "nullable|numeric",
            auxiliary_engine_type: "nullable|string|max:100",
            auxiliary_engine_power: "nullable|numeric",
            main_engine_bhp: "nullable|numeric|min:0",
            auxiliary_engine_bhp: "nullable|numeric|min:0",
            generator: "nullable|string|max:100",
            max_speed: "nullable|numeric|min:0",
            service_speed: "nullable|numeric|min:0",
            fuel_consumption: "nullable|numeric|min:0",
            fuel_oil_capacity: "nullable|numeric|min:0",
            fuel_type: "nullable|string|max:100",
            fresh_water_capacity: "nullable|numeric|min:0",
            diesel_oil_capacity: "nullable|numeric|min:0",
            bwts: "nullable|numeric|min:0",
            bwt: "nullable|numeric|min:0",
            propeller_type: "nullable|string|max:100",
            num_propellers: "nullable|numeric|min:0",
            bow_thruster: "nullable|string|max:100",

            // Cargo Information
            cargo_tank_coating: "nullable|string|max:100",
            total_tank_capacity: "nullable|numeric|min:0",
            cargo_tank_liquide_capacity: "nullable|numeric|min:0",
            cargo_tank_slop_capacity: "nullable|numeric|min:0",
            cargo_pump: "nullable|string|max:100",

            // Passenger Details
            pax: "nullable|numeric|min:0",

            // Operational and Environmental Details
            next_ss: "nullable|date",
            next_dd: "nullable|date",
            registration_year: "nullable|numeric:4",
            registration_month: "nullable|string",
            manufacture_year: "nullable|numeric:4",
            manufacture_month: "nullable|string",
            delivery: "nullable|date",
            last_inspection_date: "nullable|date",

            // Description and Status
            description: "nullable|string",
            status: "nullable|string",
            publish_status: "nullable|string",
            featured: "nullable|boolean",

            // Pricing
            price: "nullable|numeric|min:0",
            discount_percentage: "nullable|numeric|min:0|max:100",
        },
    });
}

if (document.querySelector("[name='charted_vessel']")) {
    new FormSubmitHandler({
        picture_load_selector: "[data-type='load-pictures']",
        picture_reset_selector: "[data-type='reset-picture']",

        form_selector: "[name='charted_vessel']",

        picture_grid_selector: ".picture-grid",

        picture_input_selector: "input[name='pictures[]']",

        success_redirect: "/charter",
        validation: {
            // 'flag': 'required',
            // 'classification': 'required',
            // 'grain_capacity': 'required',
            // 'bale_capacity': 'required',
            // 'ship_type': 'required',
            // 'build_year': 'required',
            // 'number_of_holds': 'required',
            // 'number_of_hatches': 'required',
            // 'draft': 'required',
            // 'tons_per_centimeter': 'required',
            // 'capacity_per_hold': 'required',
            // 'length_overall': 'required',
            // 'length_between_perpendiculars': 'required',
            // 'breadth_moulded': 'required',
            // 'depth_moulded': 'required',
            // 'gross_tonnage': 'required',
            // 'net_tonnage': 'required',
            // 'ballast_speed': 'required',
            // 'loaded_speed': 'required',
            // 'idle_speed': 'required',
            // 'idle_fuel_consumption': 'required',
            // 'working_speed': 'required',
            // 'working_fuel_consumption': 'required',
            // 'eco_speed': 'required',
            // 'phone_contact': 'required'
        },
    });
}

if (document.querySelector("[name='boats']")) {
    new FormSubmitHandler({
        picture_load_selector: "[data-type='load-pictures']",
        picture_reset_selector: "[data-type='reset-picture']",
        video_load_selector: "[data-type='load-video']",
        video_reset_selector: "[data-type='reset-video']",

        form_selector: "[name='boats']",

        picture_grid_selector: ".picture-grid",
        video_player_selector: "video",

        picture_input_selector: "input[name='pictures[]']",
        video_input_selector: "input[name='video']",

        success_redirect: "/office/boats",
        validation: {
            model_id: "required",

            // Boat Details
            name: "nullable|max:255",
            condition: "nullable|max:100",
            price: "nullable|numeric|min:0",
            boat_type: "nullable|max:100",
            class: "nullable|max:100",
            length: "nullable|numeric|min:0",
            fuel_type: "nullable|max:100",
            hull_material: "nullable|max:100",
            registration_year: "nullable|numeric",
            registration_month: "nullable",
            manufacture_year: "nullable|numeric",
            manufacture_month: "nullable",
            steering: "nullable|max:100",
            trailer_type: "nullable|max:100",

            // Measurements
            loa: "nullable|numeric|min:0",
            length_at_water_line: "nullable|numeric|min:0",
            length_on_deck: "nullable|numeric|min:0",
            beam: "nullable|numeric|min:0",
            max_draft: "nullable|numeric|min:0",
            min_draft: "nullable|numeric|min:0",
            freeboard: "nullable|numeric|min:0",
            displacement: "nullable|numeric|min:0",
            dry_weight: "nullable|numeric|min:0",
            max_bridge_clearance: "nullable|numeric|min:0",
            cabin_headroom: "nullable|numeric|min:0",

            // Speed Specifications
            max_speed: "nullable|numeric|min:0",
            cruising_speed: "nullable|numeric|min:0",

            // Propulsion
            engine_type: "nullable|max:100",
            engine_make: "nullable|max:100",
            engine_model: "nullable|max:100",
            engine_year: "nullable|numeric",
            engine_power: "nullable|numeric|min:0",
            engine_stroke: "nullable|max:100",
            numeric_of_engines: "nullable|numeric|min:0",
            drive_type: "nullable|max:100",
            engine_location: "nullable|max:100",
            propeller_type: "nullable|max:100",
            propeller_material: "nullable|max:100",
            engine_usage_hours: "nullable|numeric|min:0",

            // Specifications
            built_country: "nullable|max:100",
            designer: "nullable|max:100",
            cabins: "nullable|numeric|min:0",
            heads: "nullable|numeric|min:0",
            max_passengers: "nullable|numeric|min:0",
            seating_capacity: "nullable|numeric|min:0",
            fuel_tanks: "nullable|max:100",
            fresh_water_tanks: "nullable|max:100",
            holding_tanks: "nullable|max:100",
            hull_shape: "nullable|max:100",
            windlass: "nullable",
            storage_type: "nullable|max:100",

            // Description and Status
            description: "nullable",
            status: "nullable",
            publish_status: "nullable",
            featured: "nullable|boolean",

            // Pricing
            discount_percentage: "nullable|numeric|min:0|max:100",
        },
    });
}

new Intl({
    phone_input: document.querySelector("input[name='phonenumber']"),
    hidden_input: document.querySelector("input[name='phone_number']"),
});

new Intl({
    phone_input: document.querySelector("input[name='phonenumeric']"),
    hidden_input: document.querySelector("input[name='phone_numeric']"),
});

new Intl({
    phone_input: document.querySelector("input[name='phone']"),
    hidden_input: document.querySelector("input[name='phone_numeric']"),
});

new Intl({
    phone_input: document.querySelector("input[name='phone_contact']"),
    hidden_input: document.querySelector("input[name='tephone_numeric']"),
});

new Intl({
    phone_input: document.querySelector("input[name='phone']"),
    hidden_input: document.querySelector("input[name='phone_number']"),
});

new Intl({
    phone_input: document.querySelector("input[name='tephone_number']"),
    hidden_input: document.querySelector("input[name='phone_contact']"),
});

const toggleAside = document.querySelectorAll(".table-aside-toggle");
toggleAside.forEach((element) => {
    element.addEventListener(
        "click",
        debounce((e) => {
            e.preventDefault();

            const parentDiv = element.closest(".table-content");

            const aside = parentDiv.querySelector(".side-table");
            const model = element.getAttribute("data-model");
            const url = element.getAttribute("data-url");

            const container = parentDiv.querySelector(".side-content");
            container.innerHTML = "";

            if (url) {
                fetch(url)
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error(
                                `HTTP error! Status: ${response.status}`
                            );
                        }

                        return response.json();
                    })
                    .then((content) => {
                        if (model == "vehicle") {
                            const vehicle = content.vehicle;
                            const pictures = vehicle.pictures;
                            const videos = vehicle.videos;
                            const accessories = content.accessories;

                            createTitleElement(vehicle, container);
                            populateDetailsList(vehicle, container);
                            populateTable(vehicle, container);
                            populateAccessory(vehicle, container, accessories);
                            populateImages(pictures, container);
                            populateVideo(videos, container);
                        }

                        if (model == "vessel") {
                            const vessel = content.vessel;
                            const pictures = vessel.pictures;
                            const videos = vessel.videos;
                            const accessories = content.accessories;

                            createTitleElement(vessel, container);
                            populateDetailsList(vessel, container);
                            populateTable(vessel, container);
                            populateAccessory(vessel, container, accessories);
                            populateImages(pictures, container);
                            populateVideo(videos, container);
                        }

                        if (model == "boat") {
                            const boat = content.boat;
                            const pictures = boat.pictures;
                            const videos = boat.videos;
                            const accessories = content.accessories;

                            createTitleElement(boat, container);
                            populateDetailsList(boat, container);
                            populateTable(boat, container);
                            populateAccessory(boat, container, accessories);
                            populateImages(pictures, container);
                            populateVideo(videos, container);
                        }

                        if (model == "customer" || model == "user") {
                            const customer = content.customer ?? content.user;
                            createTitleElement(customer, container);
                            populateTable(customer, container);
                        }

                        if (model == "charterVessel") {
                            const charterVessel = content.vessel;
                            const pictures = charterVessel.pictures;

                            createTitleElement(charterVessel.user, container);
                            populateTable(charterVessel, container);
                            populateImages(pictures, container);
                        }

                        if (model == "charterCargo") {
                            console.log(content);
                            const charterCargo = content.cargo;

                            createTitleElement(charterCargo.user, container);
                            populateTable(charterCargo, container);
                        }
                    })
                    .catch((error) => {
                        console.error("Fetch error:", error.message);
                    });
            }

            if (aside) {
                if (aside.classList.contains("active")) {
                    aside.classList.remove("active");
                    container.innerHTML = "";
                } else {
                    aside.classList.add("active");
                }
            }
        }, 2000)
    );
});

function createTitleElement(data, container) {
    const heading = document.createElement("h3");
    const titleText = document.createElement("span");

    heading.className = "section-title";

    if (data.title) {
        titleText.textContent = data.title;
    } else {
        const makerName =
            data.model?.maker?.name ||
            data.category?.name ||
            data.name ||
            data.firstname ||
            "";
        const modelName = data.model?.name || "";
        const manufactureYear = data.manufacture_year || "";
        titleText.textContent = `${makerName} ${modelName} ${manufactureYear}`;
    }

    heading.appendChild(titleText);
    container.appendChild(heading);
}

function populateImages(pictures, container) {
    if (pictures.length > 0) {
        const imageGrid = document.createElement("div");
        const imageGridContent = document.createElement("div");
        const imageGridTitle = document.createElement("h3");
        const titleText = document.createElement("span");

        imageGrid.className = "image-grid";
        imageGridContent.className = "grid-content";

        imageGridTitle.className = "section-title";
        titleText.textContent = "Images";

        imageGridTitle.appendChild(titleText);

        pictures.forEach((picture) => {
            const figure = document.createElement("figure");
            const image = document.createElement("img");

            image.className = "grid-item";
            image.src = `/storage/${picture.file_path}`;
            figure.appendChild(image);

            imageGridContent.appendChild(figure);
        });

        imageGrid.appendChild(imageGridTitle);
        imageGrid.appendChild(imageGridContent);
        container.appendChild(imageGrid);
    }
}

function populateVideo(video, container) {
    if (video.length > 0) {
        const videoUrl = `/storage/${video[0].file_path}`;

        const videoPreview = document.createElement("div");
        const videoTitle = document.createElement("h3");
        const titleText = document.createElement("span");
        const videoElement = document.createElement("video");

        videoTitle.className = "section-title";
        titleText.textContent = "Video";
        videoTitle.appendChild(titleText);

        videoElement.controls = true;
        videoElement.width = 400;
        videoElement.src = videoUrl;

        videoPreview.appendChild(videoTitle);
        videoPreview.appendChild(videoElement);
        container.appendChild(videoPreview);
    }
}

function populateTable(data, container) {
    const table = document.createElement("table");
    table.className = "side-data-table";
    container.appendChild(table);

    Object.entries(data).forEach(([key, value]) => {
        let displayedValue = value;

        if (key === "pictures" || key === "accessories") {
            return;
        } else if (
            typeof value === "object" &&
            value !== null &&
            "name" in value &&
            (key != "pictures" || key != "accessories")
        ) {
            // Display the 'name' property for other objects
            displayedValue = value.name;
        }

        // Replace underscores with spaces and convert to Capital case
        const formattedKey = key
            .replace(/_/g, " ")
            .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());

        // Special case for "Id" to "ID"
        const finalKey = formattedKey === "Id" ? "ID" : formattedKey;

        const row = table.insertRow();
        const titleCell = row.insertCell(0);
        titleCell.className = "title";
        titleCell.textContent = finalKey;

        const valueCell = row.insertCell(1);
        valueCell.textContent = displayedValue;
    });
}

function populateDetailsList(data, container) {
    const detailsList = document.createElement("ul");
    detailsList.className = "main-details";

    const detailsMap = {
        mileage: "Mileage",
        registration_year: "Year",
        engine_size: "Engine",
        transmission: "Trans.",
        location: "Location",
        speed: "Speed",
        built_year: "Built Year",
        grt: "GRT",
        nrt: "NRT",
        dwt: "DWT",
        pax: "PAX",
        fuel_type: "Fuel",
        boat_type: "Type",
        engin_model: "Engin Model",
    };

    Object.keys(detailsMap).forEach((key) => {
        if (key in data && data[key] !== null && data[key] !== "") {
            const listItem = document.createElement("li");

            const titleSpan = document.createElement("span");
            titleSpan.classList.add("title");
            titleSpan.textContent = detailsMap[key];

            const valueSpan = document.createElement("span");
            valueSpan.classList.add("value");
            valueSpan.textContent = data[key];

            listItem.appendChild(titleSpan);
            listItem.appendChild(valueSpan);

            detailsList.appendChild(listItem);
        }
    });

    container.appendChild(detailsList);
}

function populateAccessory(data, container, accessories) {
    const featuresDiv = document.createElement("div");
    const title = document.createElement("h3");
    const titleText = document.createElement("span");

    featuresDiv.className = "features";
    title.className = "section-title";
    titleText.textContent = "Features";

    title.appendChild(titleText);
    featuresDiv.appendChild(title);

    const accessoryList = document.createElement("ul");

    accessories.forEach((accessory) => {
        const isAttached = data.accessories.some(
            (attached) => attached.accessory_id === accessory.id
        );
        const li = document.createElement("li");
        li.textContent = accessory.name;
        li.className = isAttached ? "attached_on" : "attached_off";
        accessoryList.appendChild(li);
    });
    featuresDiv.appendChild(accessoryList);
    container.appendChild(featuresDiv);
}

document.addEventListener("DOMContentLoaded", function () {
    var togglers = document.querySelectorAll(".toggler");
    var searchFilters = document.querySelectorAll(".filter-popup");
    var overlays = document.querySelectorAll(".overlay");

    togglers.forEach(function (toggler, index) {
        toggler.addEventListener("click", function () {
            searchFilters[index].classList.toggle("filter-modal");
            overlays[index].classList.toggle("show");
        });
    });

    overlays.forEach(function (overlay, index) {
        overlay.addEventListener("click", function () {
            searchFilters[index].classList.remove("filter-modal");
            overlays[index].classList.remove("show");
        });
    });
});


if (document.querySelector('form[name="vehicle-filter"] select[name="maker"]')) {
    const modelFetch = new DataFetcher({
        selector: 'form[name="vehicle-filter"] select[name="maker"]'
    });

    modelFetch.selector.addEventListener("dataFetched", (event) => {
        const models = event.detail.data;
        const modelSelector = document.querySelector(
            'form[name="vehicle-filter"] select[name="model_id"]'
        );
        const option = document.createElement("option");

        option.value = "";
        option.textContent = "Vehicle Model";

        modelSelector.innerHTML = "";
        modelSelector.appendChild(option);

        if (models) {
            for (var model of models) {
                const option = document.createElement("option");
                option.value = model.id;
                option.textContent = model.name;

                modelSelector.appendChild(option);
            }
        }
    });
}

if (document.querySelector('form[name="vehicles"] select[name="maker"]')) {
    const modelFetch = new DataFetcher({
        selector: 'form[name="vehicles"] select[name="maker"]'
    });

    modelFetch.selector.addEventListener("dataFetched", (event) => {
        const models = event.detail.data;
        const modelSelector = document.querySelector(
            'form[name="vehicles"] select[name="model_id"]'
        );
        const option = document.createElement("option");

        option.value = "";
        option.textContent = "Vehicle Model";

        modelSelector.innerHTML = "";
        modelSelector.appendChild(option);

        if (models) {
            for (var model of models) {
                const option = document.createElement("option");
                option.value = model.id;
                option.textContent = model.name;

                modelSelector.appendChild(option);
            }
        }
    });
}

if (document.querySelector('form[name="boats"] select[name="maker"]')) {
    const modelFetch = new DataFetcher({
        selector: 'form[name="boats"] select[name="maker"]',
    });

    modelFetch.selector.addEventListener("dataFetched", (event) => {
        const models = event.detail.data;
        const modelSelector = document.querySelector(
            'form[name="boats"] select[name="model_id"]'
        );
        const option = document.createElement("option");
        option.value = "";
        option.textContent = "Boat Model";

        modelSelector.innerHTML = "";
        modelSelector.appendChild(option);

        if (models) {
            for (var model of models) {
                const option = document.createElement("option");
                option.value = model.id;
                option.textContent = model.name;

                modelSelector.appendChild(option);
            }
        }
    });
}

// setting page
const tabs = document.querySelectorAll(".tab-item");

if (tabs.length > 0) {
    // Retrieve the active tab ID from local storage
    let activeTabId = localStorage.getItem("activeSettingTab");

    // If no active tab is set, default to the first tab and store it in local storage
    if (!activeTabId) {
        const firstTab = tabs[0];
        activeTabId = firstTab.getAttribute("id");

        // Activate the first tab and its content
        firstTab.classList.add("active");
        const firstTabContent = document.querySelector(
            `[data-target="${activeTabId}"]`
        );
        if (firstTabContent) {
            firstTabContent.classList.add("active");
        }

        // Store the first tab as the active tab in local storage
        localStorage.setItem("activeSettingTab", activeTabId);
    } else {
        // Restore the active tab and its content from local storage
        const storedActiveTab = document.getElementById(activeTabId);
        if (storedActiveTab) {
            storedActiveTab.classList.add("active");
            const storedTabContent = document.querySelector(
                `[data-target="${activeTabId}"]`
            );
            if (storedTabContent) {
                storedTabContent.classList.add("active");
            }
        }
    }

    // Add event listeners to tabs for click functionality
    tabs.forEach((tab) => {
        tab.addEventListener("click", function (event) {
            const target = event.currentTarget;
            const active = document.querySelector(".tab-item.active");
            const tabContent = document.querySelector(
                `[data-target="${target.getAttribute("id")}"]`
            );
            const activeTabContent = document.querySelector(
                `[data-target="${active.getAttribute("id")}"]`
            );

            if (target !== active) {
                // Deactivate the currently active tab and its content
                active.classList.remove("active");
                activeTabContent.classList.remove("active");

                // Activate the clicked tab and its content
                target.classList.add("active");
                tabContent.classList.add("active");

                // Store the new active tab ID in local storage
                localStorage.setItem(
                    "activeSettingTab",
                    target.getAttribute("id")
                );
            }
        });
    });
}

/// location
const sessions = document.querySelectorAll(".session");
sessions.forEach((session) => {
    const text = session.querySelector(".data");
    const location = session.querySelector(".location");

    const ip = session.getAttribute("data-ip");
    const date = session.getAttribute("data-date");

    fetch(`https://ipapi.co/${ip}/json/`)
        .then((response) => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.json();
        })
        .then((data) => {
            if (
                data.city != undefined &&
                date.region != undefined &&
                date.countr != undefined
            ) {
                text.textContent = `${data.city} ${ip}`;
                location.textContent = `Seen in ${data.region} ${
                    data.country_name
                } ${moment(date).fromNow()}`;
            } else {
                text.textContent = `${ip}`;
                location.textContent = `Since ${moment(date).fromNow()}`;
            }
        })
        .catch((error) => {
            text.textContent = `${ip}`;
            location.textContent = `Since ${moment(date).fromNow()}`;
        });
});

document.addEventListener("DOMContentLoaded", function () {
    const rangeYearFrom = document.querySelector(
        'select[name="rangeYearFrom"]'
    );
    const rangeYearTo = document.querySelector('select[name="rangeYearTo"]');
    const currentYear = new Date().getFullYear();
    const startYear = null;

    if (rangeYearFrom) {
        rangeYearFrom.addEventListener("change", function () {
            const selectedYear = parseInt(this.value);
            updateRangeYearTo(selectedYear);
        });
    }

    function updateRangeYearTo(fromYear) {
        rangeYearTo.innerHTML = "";
        rangeYearTo.innerHTML = '<option value="" selected disabled></option>';

        if (fromYear < currentYear) {
            for (let year = fromYear; year <= currentYear; year++) {
                const option = document.createElement("option");
                option.value = year;
                option.textContent = year;
                rangeYearTo.appendChild(option);
            }
        }
    }
});

// calculator radio check
document.addEventListener("DOMContentLoaded", function () {
    var radioSpans = document.querySelectorAll(".form-radio .checkmark");

    radioSpans.forEach(function (span) {
        span.addEventListener("click", function () {
            var radioInput = span.previousElementSibling;
            if (radioInput) {
                radioInput.checked = true;
            }
        });
    });
});

document.addEventListener("DOMContentLoaded", () => {
    const addButton = document.getElementById("add");
    const removeButton = document.getElementById("remove");
    const searchInput = document.querySelector(".search");
    const picklistOptions = document.querySelector(".picklist-options");
    const picklistSelected = document.querySelector(".picklist-selected");

    if (
        addButton &&
        removeButton &&
        searchInput &&
        picklistOptions &&
        picklistSelected
    ) {
        addButton.addEventListener("click", () => {
            moveSelectedOption(picklistOptions, picklistSelected, true);
        });

        if (addButton) {
            addButton.addEventListener("click", () => {
                moveSelectedOption(picklistOptions, picklistSelected, true);
            });
        }

        if (removeButton) {
            removeButton.addEventListener("click", () => {
                moveSelectedOption(picklistSelected, picklistOptions, false);
            });
        }

        if (searchInput) {
            searchInput.addEventListener("input", () => {
                filterOptions(searchInput.value);
            });
        }

        function moveSelectedOption(source, destination, isAddAction) {
            const selectedOption = source.selectedOptions[0];
            if (
                isAddAction &&
                selectedOption &&
                destination.options.length === 0
            ) {
                selectedOption.setAttribute("selected", "");
                destination.add(selectedOption);
            } else if (!isAddAction && selectedOption) {
                selectedOption.removeAttribute("selected");
                destination.add(selectedOption);
            }
        }

        function filterOptions(query) {
            const options = Array.from(picklistOptions.options);
            options.forEach((option) => {
                const text = option.text.toLowerCase();
                const match = text.includes(query.toLowerCase());
                option.style.display = match ? "" : "none";
            });
        }
    }
});

const addItemButton = document.getElementById("addItemButton");
if (addItemButton) {
    document
        .getElementById("addItemButton")
        .addEventListener("click", function () {
            // Clone the last item
            let originalItems = document.querySelectorAll(".item-form");
            let lastItem = originalItems[originalItems.length - 1];
            let newItem = lastItem.cloneNode(true);

            // Remove existing close buttons
            let existingCloseButtons =
                newItem.querySelectorAll(".close-button");
            existingCloseButtons.forEach((button) => button.remove());

            let itemCount = originalItems.length + 1;
            let itemTitle = newItem.querySelector(".item-title");
            itemTitle.innerText = `Item ${itemCount}`;

            let inputs = newItem.querySelectorAll("input, textarea");
            inputs.forEach((input) => {
                // Update name attributes to make them unique
                let name = input.getAttribute("name");
                if (name) {
                    let newName = name.replace(/\[\d+\]/, `[${itemCount - 1}]`);
                    input.setAttribute("name", newName);

                    // Update corresponding label's for attribute
                    let label = input.previousElementSibling;
                    if (label && label.tagName.toLowerCase() === "label") {
                        label.setAttribute("for", newName);
                    }
                }

                input.value = "";
            });

            // Create close button
            let closeButton = document.createElement("button");
            closeButton.innerText = "Delete Item";
            closeButton.className = "btn btn-danger btn-sm close-button";
            closeButton.addEventListener("click", function () {
                newItem.remove();
            });

            // Find the item-content div and append the close button at the end
            let itemContent = newItem.querySelector(".item-content");
            if (itemContent) {
                itemContent.appendChild(closeButton);
            }

            document
                .querySelector(".item-container")
                .insertBefore(
                    newItem,
                    document.querySelector(".section-control")
                );
        });
}

const editItemDeleteButtons = document.querySelectorAll(".close-button");
editItemDeleteButtons.forEach((deleteItemButton) => {
    deleteItemButton.addEventListener("click", () => {
        const parentDiv = btn.closest(".item-form");
        if (parentDiv) {
            parentDiv.remove();
        }
    });
});

document.addEventListener("DOMContentLoaded", function () {
    const form = document.querySelector('form[name="invoice"]');

    function normalizeFieldName(fieldName) {
        return fieldName
            .replace(/\./g, " ")
            .replace(/_/g, " ")
            .replace(/\d+/g, "");
    }

    function normalizeErrorMessage(message) {
        return message
            .replace(/\./g, " ")
            .replace(/_/g, " ")
            .replace(/\d+/g, "");
    }

    function handleValidationErrors(errors) {
        for (let field in errors) {
            let inputField = document.querySelector(`[name="${field}"]`);

            if (inputField) {
                let formGroup = inputField.closest(".form-group");

                if (formGroup) {
                    let feedbackElement =
                        formGroup.querySelector(".invalid-feedback");
                    if (
                        feedbackElement &&
                        feedbackElement.classList.contains("invalid-feedback")
                    ) {
                        feedbackElement.textContent = normalizeErrorMessage(
                            errors[field][0]
                        );
                        feedbackElement.style.display = "block";
                    }
                    if (formGroup) {
                        formGroup.classList.add("has-error");
                    }
                }
            } else {
                let match = field.match(/^items\.(\d+)\.(.+)$/);
                if (match) {
                    let index = match[1];
                    let subfield = match[2];
                    let arrayInput = document.querySelector(
                        `[name="items[${index}][${subfield}]"]`
                    );
                    if (arrayInput) {
                        let feedbackElement = arrayInput.nextElementSibling;
                        if (
                            feedbackElement &&
                            feedbackElement.classList.contains(
                                "invalid-feedback"
                            )
                        ) {
                            feedbackElement.textContent = normalizeErrorMessage(
                                errors[field][0]
                            );
                            feedbackElement.style.display = "block";
                        }
                        let formGroup = arrayInput.closest(".form-group");
                        if (formGroup) {
                            formGroup.classList.add("has-error");
                        }
                    }
                }
            }
        }
    }

    if (form) {
        form.addEventListener(
            "submit",
            debounce((event) => {
                event.preventDefault();

                // Clean all validation errors
                document
                    .querySelectorAll(".form-group.has-error")
                    .forEach((el) => {
                        el.classList.remove("has-error");
                    });

                document.querySelectorAll(".invalid-feedback").forEach((el) => {
                    el.textContent = "";
                    el.style.display = "none";
                });

                const formData = new FormData(form);

                fetch(form.action, {
                    method: "POST",
                    headers: {
                        "X-CSRF-TOKEN": csrfToken,
                    },
                    body: formData,
                })
                    .then((response) => {
                        if (!response.ok) {
                            if (response.status === 422) {
                                return response.json();
                            } else {
                                throw new Error("Network response was not ok");
                            }
                        }
                        return response.json();
                    })
                    .then((data) => {
                        if (data.errors) {
                            const errors = data.errors;
                            handleValidationErrors(errors);
                        }

                        if (data.invoice_url) {
                            window.location.href = data.invoice_url;
                        }
                    })
                    .catch((error) => {});
            }, 5000)
        );
    }
});

document.addEventListener("DOMContentLoaded", function () {
    const deleteButtons = document.querySelectorAll(".delete-btn");
    if (deleteButtons) {
        const modal = document.getElementById("deleteModal");
        const confirmDelete = document.getElementById("confirmDelete");
        const cancelDelete = document.getElementById("cancelDelete");
        const closeModal = document.getElementById("closeModal");

        let url = null;
        deleteButtons.forEach((button) => {
            button.addEventListener("click", function () {
                url = this.getAttribute("data-delete-url");
                modal.style.display = "block"; // Show the modal
            });
        });

        if (cancelDelete && closeModal && confirmDelete) {
            // Close modal on cancel button click
            cancelDelete.onclick = function () {
                modal.style.display = "none";
            };

            // Close modal on close button click
            closeModal.onclick = function () {
                modal.style.display = "none";
            };

            // Confirm deletion
            confirmDelete.addEventListener(
                "click",
                debounce(() => {
                    if (url) {
                        fetch(url, {
                            method: "DELETE",
                            headers: {
                                "X-CSRF-TOKEN": csrfToken,
                                "Content-Type": "application/json",
                            },
                        })
                            .then((response) => {
                                if (response.ok) {
                                    modal.style.display = "none";
                                    location.reload();
                                } else {
                                    const modalContent =
                                        modal.querySelector(".bottom");
                                    if (modalContent) {
                                        modalContent.innerHTML = `
                                <h2>Error occurred!</h2>
                                <p>An unexpected error occurred. Please try again later.</p>
                            `;
                                    }
                                }
                            })
                            .catch((error) => {
                                const modalContent =
                                    modal.querySelector(".bottom");
                                if (modalContent) {
                                    modalContent.innerHTML = `
                            <h2>Error occurred!</h2>
                            <p>An unexpected error occurred. Please try again later.</p>
                        `;
                                }
                            });
                    }
                }, 5000)
            );

            // Close the modal when clicking outside of it
            window.onclick = function (event) {
                if (event.target == modal) {
                    modal.style.display = "none";
                }
            };
        }
    }
});

document.addEventListener("DOMContentLoaded", function () {
    const tabs = document.querySelectorAll(".tabb");

    tabs.forEach((tab) => {
        tab.addEventListener("click", function () {
            // Remove active class from all tabs
            tabs.forEach((t) => t.classList.remove("active"));

            // Add active class to the clicked tab
            this.classList.add("active");
        });
    });
});

document.querySelectorAll(".message-summary").forEach((item) => {
    item.addEventListener("click", () => {
        document
            .querySelectorAll(".message")
            .forEach((message) => message.classList.remove("active"));
        item.closest(".message").classList.add("active");
    });
});

// Activate the first message by default
//   document.querySelector('.message-item').click();

// show or hide the compose message pop up:

const emailInput = document.querySelector(".emails");
const emailPreview = document.querySelector(".preview");
const emailForm = document.querySelector(".mail-form");
var messageForm = document.getElementById("messageForm");

if (emailInput && emailPreview && emailForm) {
    let emailArray = [];

    emailInput.addEventListener("keyup", (event) => {
        if (event.keyCode === 32 || event.keyCode === 188) {
            // Space or comma key
            event.preventDefault();
            processEmails();
        }
    });

    emailInput.addEventListener("blur", () => {
        processEmails();
    });

    // reply
    const reply = document.querySelector(".reply");

    reply.addEventListener("click", (e) => {
        const from = reply.getAttribute("data-from");
        const subject = reply.getAttribute("data-subject");
        const uid = reply.getAttribute("data-uid");
        const folder = reply.getAttribute("data-folder");

        const uidInput = document.createElement("input");
        uidInput.type = "hidden";
        uidInput.name = "uid";
        uidInput.value = uid;

        const folderInput = document.createElement("input");
        folderInput.type = "hidden";
        folderInput.name = "folder";
        folderInput.value = folder;

        emailForm.appendChild(uidInput);
        emailForm.appendChild(folderInput);

        const emailInput = document.querySelector(".emails");
        const subjectInput = document.querySelector('[name="subject"]');
        emailInput.value = from;
        subjectInput.value = subject;
        processEmails();

        document.getElementById("toggleButton").click();
    });

    if (document.querySelector(".close-button")) {
        messageForm.classList.remove("show");
        setTimeout(function () {
            messageForm.style.visibility = "hidden";
        }, 300);
    }

    if (document.getElementById("toggleButton")) {
        document
            .getElementById("toggleButton")
            .addEventListener("click", function () {
                if (messageForm.classList.contains("show")) {
                    messageForm.classList.remove("show");
                    setTimeout(function () {
                        messageForm.style.visibility = "hidden";
                    }, 300); // Match the duration of the CSS transition
                } else {
                    messageForm.style.visibility = "visible";
                    setTimeout(function () {
                        messageForm.classList.add("show");
                    }, 10); // Slight delay to ensure visibility: visible is applied
                }
            });
    }

    function processEmails() {
        const emailValue = emailInput.value.trim();
        if (emailValue.length > 0) {
            const emails = emailValue
                .split(/[\s,]+/)
                .filter((email) => email.length > 0);
            emails.forEach((email) => {
                if (isValidEmail(email) && !emailArray.includes(email)) {
                    emailArray.push(email);
                    addEmailToPreview(email);
                    addEmailToForm(email);
                }
            });
            emailInput.value = "";
        }
    }

    function isValidEmail(email) {
        // Regular expression to validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    function addEmailToPreview(email) {
        // Create a new div to hold the email and close button
        const emailDiv = document.createElement("div");
        emailDiv.classList.add("email-preview-item");

        // Create a span to display the email
        const emailSpan = document.createElement("span");
        emailSpan.classList.add("email-text");
        emailSpan.textContent = email;

        // Create a button for removing the email
        const closeButton = document.createElement("a");
        closeButton.classList.add("close-button");
        closeButton.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-x">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M18 6l-12 12" />
                <path d="M6 6l12 12" />
            </svg>
        `;

        closeButton.addEventListener("click", function () {
            emailDiv.remove();
            emailArray = emailArray.filter((e) => e !== email);
            removeEmailFromForm(email);
        });

        emailDiv.appendChild(emailSpan);
        emailDiv.appendChild(closeButton);

        emailPreview.appendChild(emailDiv);
    }

    function addEmailToForm(email) {
        // Create a hidden input for the email
        const hiddenInput = document.createElement("input");
        hiddenInput.type = "hidden";
        hiddenInput.name = "emails[]";
        hiddenInput.value = email;
        hiddenInput.classList.add("email-hidden-input");
        emailForm.appendChild(hiddenInput);
    }

    function removeEmailFromForm(email) {
        // Find and remove the hidden input associated with the email
        const hiddenInputs = emailForm.querySelectorAll(".email-hidden-input");
        hiddenInputs.forEach((input) => {
            if (input.value === email) {
                input.remove();
            }
        });
    }
}

// send email

if (emailForm) {
    new EmailSend(emailForm, messageForm);
}
